var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"data-table",class:[
    _vm.itemsLoading ? 'data-table--loading' : '',
    _vm.canBeSelected ? 'data-table--selectable' : '' ],attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.searchTerm,"sort-by":_vm.sortBy.value,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","item-class":_vm.itemClass},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(_vm.itemsLoading)?{key:"body",fn:function(){return [(_vm.$vuetify.breakpoint.lgAndDown)?_vm._l((5),function(item){return _c('logs-table-item-skeleton-mobile',{key:item})}):[_c('logs-table-sort-skeleton'),_c('logs-table-skeleton-item')]]},proxy:true}:null,{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true},{key:"no-results",fn:function(){return [(_vm.searchTerm)?_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":function($event){return _vm.$emit('clearFilters')}}}):_vm._e()]},proxy:true},(!_vm.$vuetify.breakpoint.mobile && _vm.items.length)?{key:"header",fn:function(ref){
  var isMobile = ref.isMobile;
  var props = ref.props;
  var on = ref.on;
return [(!_vm.itemsLoading)?_c('table-custom-sort',_vm._g({attrs:{"isMobile":isMobile,"props":props,"v-on":on}},_vm.$listeners)):_vm._e()]}}:null,{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex actions-row"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":item.infoButtonLoading},on:{"click":function($event){return _vm.$emit('action-button-info', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$help")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.ftp.show')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-edit', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$edit2")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.ftp.edit')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-delete', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$thrash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.ftp.delete')))])])],1)]}},{key:"item.user",fn:function(ref){
  var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(item.user)+" ")])]}},{key:"item.updated_at",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._t("updated_at",null,null,item)],2)]}},{key:"item.usage_quota",fn:function(ref){
  var item = ref.item;
return [(_vm.usageSupported)?_c('div',{staticClass:"d-flex align-center"},[_c('progress-chart',{style:("max-width: 150px; min-width: 150px"),attrs:{"value":parseInt(item.diskused),"maxValue":item.diskquota !== 'unlimited'
              ? parseInt(item.diskquota)
              : parseInt(item.diskused)}}),_c('span',{staticClass:"usage-quota"},[_c('b',{staticClass:"font-weight-600"},[_vm._v(_vm._s(_vm.$t('form.value.sizeMB', {value: item.diskused})))]),_vm._v(" / "),_c('span',[(item.diskquota !== 'unlimited')?_c('span',[_vm._v(_vm._s(_vm.$t('form.value.sizeMB', {value: item.diskquota})))]):_c('span',[_vm._v(_vm._s(item.diskquota))])])])],1):_c('div',{staticClass:"ps-7"},[_c('v-menu',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","origin":"top center","offset-overflow":"","close-on-content-click":false,"open-on-hover":"","content-class":"v-tooltip","nudge-bottom":"24px","nudge-left":"175px","min-width":"350px","max-width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 d-flex align-center",attrs:{"x-small":"","icon":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","size":"20"}},[_vm._v("$alertcircle")])],1)]}}],null,true)},[_c('div',{staticClass:"px-4 py-4"},[_vm._v(" "+_vm._s(_vm.$t('tooltip.hosting.ftp.usageNotSupported'))+" ")])])],1)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"item",fn:function(ref){
              var item = ref.item;
              var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"w-100"},[_c('div',{staticClass:"mobile-table-item"},[_c('div',{staticClass:"mobile-table-item__values"},[_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[0])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.user'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[_c('span',{staticClass:"bold p-3"},[_vm._v(_vm._s(item.user))])])]),_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[1])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.directory'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[_c('span',{staticClass:"bold p-3"},[_vm._v(_vm._s(item.directory))])])]),_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[2])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.usageQuota'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[(!item.usage_quota)?_c('span',{staticClass:"p-3"},[_vm._v("\"---\"")]):_c('div',{staticClass:"d-flex align-center"},[_c('progress-chart',{style:("max-width: 150px; min-width: 150px"),attrs:{"value":parseInt(item.diskused),"maxValue":item.diskquota !== 'unlimited'
                          ? parseInt(item.diskquota)
                          : parseInt(item.diskused)}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.usage_quota)+" ")])],1)])]),_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[1])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.actions'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":item.infoButtonLoading},on:{"click":function($event){return _vm.$emit('action-button-info', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$help")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.ftp.show')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-edit', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$edit2")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.ftp.edit')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('action-button-delete', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$thrash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.ftp.delete')))])])],1)])])])])])]}}:null,(_vm.items.length)?{key:"footer",fn:function(ref){
                          var props = ref.props;
return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"page":props.pagination.page,"numberOfPages":props.pagination.pageCount,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":_vm.listStyle,"itemsPerPage":props.pagination.itemsPerPage}},_vm.$listeners))],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }